import styled from 'styled-components';
import { breakpoint } from 'ui/elements/ThemeProvider/utils';

export const ImageContainer = styled.div`
  flex: 0 0 100%;
  position: relative;
  z-index: 2;
  
  ${breakpoint.lg`
    flex: 0 0 30%;
  `}
`;

export const ContentContainer = styled.div`
  background: rgb(241, 241, 241);
  padding: 30px;
`;

export const Infobox = styled.div`
  display: flex;
  flex-direction: column;
  
  ${breakpoint.lg`
    ${ContentContainer} {
      padding: ${(props) => props.$hasImage ? '30px 30px 30px 60px' : '30px'};
    }
    
    ${ImageContainer} {
      margin-right: ${(props) => props.$hasImage ? '-30px' : 0};
    }
  `}
  
  ${breakpoint.lg`
    flex-direction: row;
  `}
`;
